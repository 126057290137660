import React from 'react';
import TangleApp from './tangle-components/TangleApp';


function App() {

  return (
    <TangleApp />
  );
}

export default App;
